import { createRouter, createWebHistory, RouterView } from 'vue-router'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useGlobalStore } from '@/stores/GlobalStore'
import { useLanguageStore } from '@/stores/LanguageStore'
import { storeToRefs } from 'pinia'
import Routes from '@/routes/routes'
import tracking from '@/vendors/tracking'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/:locale',
      component: RouterView,
      children: Routes,
      beforeEnter: (to, from, next) => {
        const { locale } = storeToRefs(useLanguageStore())
        const path = to.path === '/' ? to.path + locale.value + '/' : to.path.replace(to.params.locale, locale.value)

        to.params.locale !== locale.value ? next({ path, query: to.query }) : next()
      }
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: () => {
        const { locale } = storeToRefs(useLanguageStore())
        return { name: 'Home', params: { locale: locale.value } }
      }
    }
  ],
  scrollBehavior (to, from) {
    if (to.path === from.path && to.query !== from.query) {
      return { left: window.scrollX, top: window.scrollY }
    } else {
      return { left: 0, top: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  tracking.action.view({ from, to })
  const { logout, initAuthentication } = useAuthenticationStore()

  initAuthentication()
    .then(user => {
      const { locale } = storeToRefs(useLanguageStore())

      if (to.matched.some(route => route.meta.requiresAuth) && !user) {
        next({ name: 'Login', params: { locale: locale.value }, query: { redirectTo: to.path } })
      } else if (to.matched.some(route => route.meta.requiresGuest) && user) {
        next({ name: 'Home', params: { locale: locale.value } })
      } else {
        next()
      }
    })
    .catch(error => {
      const { locale } = storeToRefs(useLanguageStore())

      if (error.status === 401) {
        logout()

        if (to.matched.some(route => route.meta.requiresAuth)) {
          next({ name: 'Login', params: { locale: locale.value }, query: { redirectTo: to.path } })
        } else {
          next()
        }
      }
    })
})

router.onError(error => {
  const globalStore = useGlobalStore()

  if (/ChunkLoadError:.*failed./i.test(error.message) || /Loading.*chunk.*failed./i.test(error.message)) {
    globalStore.$patch({ is_router_error: true })
  }
})

export default router
